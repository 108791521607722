:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-column{flex-direction:column;}
:root ._ox-hidden{overflow-x:hidden;}
:root ._oy-hidden{overflow-y:hidden;}
:root ._btlr-t-radius-12{border-top-left-radius:var(--t-radius-12);}
:root ._btrr-t-radius-12{border-top-right-radius:var(--t-radius-12);}
:root ._bbrr-t-radius-12{border-bottom-right-radius:var(--t-radius-12);}
:root ._bblr-t-radius-12{border-bottom-left-radius:var(--t-radius-12);}
:root ._bxsh-0px0pxvar--1197574931{box-shadow:0px 0px var(--t-space-4) undefined;}
:root ._pr-t-space-4--53{padding-right:var(--t-space-4--5);}
:root ._pl-t-space-4--53{padding-left:var(--t-space-4--5);}
:root ._pt-t-space-4{padding-top:var(--t-space-4);}
:root ._pb-t-space-4{padding-bottom:var(--t-space-4);}