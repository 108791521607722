:root ._dsp-flex{display:flex;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-row{flex-direction:row;}
:root ._gap-t-space-3{gap:var(--t-space-3);}
:root ._ai-center{align-items:center;}
:root ._bg-red7{background-color:var(--red7);}
:root ._pt-t-space-3{padding-top:var(--t-space-3);}
:root ._pr-t-space-3{padding-right:var(--t-space-3);}
:root ._pb-t-space-3{padding-bottom:var(--t-space-3);}
:root ._pl-t-space-3{padding-left:var(--t-space-3);}
:root ._fg-0{flex-grow:0;}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._ff-f-family{font-family:var(--f-family);}
:root ._fow-f-weight-2{font-weight:var(--f-weight-2);}
:root ._fos-f-size-2{font-size:var(--f-size-2);}
:root ._lh-f-lineHeigh112921{line-height:var(--f-lineHeight-2);}
:root ._col-color{color:var(--color);}
:root ._ussel-auto{user-select:auto;-webkit-user-select:auto;}
:root ._whiteSpace-normal{white-space:normal;}
:root ._fg-1{flex-grow:1;}
:root ._fs-1{flex-shrink:1;}
:root ._whiteSpace-pre-wrap{white-space:pre-wrap;}
:root ._fow-500{font-weight:500;}